import React, { useState, useEffect, useRef } from "react"

const CODE_PER_PX = 50 / 520

const CodeViewer = ({ code }) => {
  const [codes, setCodes] = useState(null)
  const codeRef = useRef(null)
  useEffect(() => {
    const el = codeRef.current
    if (el) {
      const codePerLine = Math.floor(el.offsetWidth * CODE_PER_PX)
      let firstPart = 0
      let perLine = 0
      let arrOfStr = []
      for (let i = 1; i <= code.length; i++) {
        if (perLine === codePerLine || code[i] === ";") {
          const slicedStr = String(code.slice(firstPart, i + 1)).trim()
          arrOfStr = [...arrOfStr, slicedStr]
          firstPart = i + 1
          perLine = 0
          continue
        }
        perLine++
      }
      setCodes(arrOfStr)
    }
  }, [code])
  const generateHTML = code => {
    let wrappedCode = code
    wrappedCode = wrappedCode.replace(
      /background:/g,
      `<span style="color: #BBFF33">background:</span>`
    )
    wrappedCode = wrappedCode.replace(
      /(background-.*:)(.*)/g,
      `<span style="color: #BBFF33">$1</span><span style="color: #33FFFF">$2</span>`
    )
    return `<span>${wrappedCode}</span>`
  }
  return (
    <div
      style={{
        width: "100%",
        color: "#fff",
        background: "#0D004D",
        padding: 80,
        borderRadius: 3,
      }}
    >
      <ul ref={codeRef}>
        {codes &&
          codes.map((code, index) => (
            <li key={index}>
              <code
                style={{ fontSize: 16, lineHeight: "32px", color: "#FF40BE" }}
              >
                <span style={{ color: "#49407A", marginRight: 40 }}>
                  {index + 1}
                </span>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: generateHTML(code) }}
                />
              </code>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default CodeViewer

export const backgroundBlendModes = [
  {
    title: "Normal",
    property: "normal",
  },
  {
    title: "Darken",
    property: "darken",
  },
  {
    title: "Multiply",
    property: "multiply",
  },
  {
    title: "Color Burn",
    property: "color-burn",
  },
  {
    title: "Lighten",
    property: "lighten",
  },
  {
    title: "Screen",
    property: "screen",
  },
  {
    title: "Color Dodge",
    property: "color-dodge",
  },
  {
    title: "Overlay",
    property: "overlay",
  },
  {
    title: "Soft Light",
    property: "soft-light",
  },
  {
    title: "Hard Light",
    property: "hard-light",
  },
  {
    title: "Differnece",
    property: "difference",
  },
  {
    title: "Exclusion",
    property: "exclusion",
  },
  {
    title: "Hue",
    property: "hue",
  },
  {
    title: "Saturation",
    property: "saturation",
  },
  {
    title: "Color",
    property: "color",
  },
  {
    title: "Luminosity",
    property: "luminosity",
  },
]

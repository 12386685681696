export const generateCode = state => {
  const {
    layers,
    isBlendMode,
    repeat: isRepeat,
    posX,
    posY,
    height,
    width,
  } = state
  let blendMode = []
  let background = []
  let repeat = []
  let backgroundPosition = []
  let backgroundSize = []
  Object.keys(layers).forEach(layer => {
    if (layers[layer].show) {
      const type = layers[layer].type
      let indBackground = []
      layers[layer].points.forEach(point => {
        indBackground.push(`${point.color} ${point.point}%`)
      })
      indBackground = indBackground.join(", ")
      if (type === "linear-gradient" || type === "repeating-linear-gradient") {
        indBackground = `${layers[layer].type}(${layers[layer].angle}deg, ${indBackground})`
      }
      if (type === "radial-gradient" || type === "repeating-radial-gradient") {
        indBackground = `${layers[layer].type}(${layers[layer].shape.type}${
          layers[layer].shape.extends ? ` ${layers[layer].shape.extends}` : ""
        } at ${layers[layer].position.x}% ${
          layers[layer].position.y
        }%, ${indBackground})`
      }
      if (type === "conic-gradient" || type === "repeating-conic-gradient") {
        indBackground = `${layers[layer].type}(from ${layers[layer].angle}deg at ${layers[layer].position.x}% ${layers[layer].position.y}%, ${indBackground})`
      }
      background.push(indBackground)
      blendMode.push(layers[layer].blendMode || "normal")
      repeat.push(layers[layer].repeat || "repeat")
      backgroundPosition.push(
        `${layers[layer].posX}${
          layers[layer].posUnit ? layers[layer].posUnit : ""
        } ${layers[layer].posY}${
          layers[layer].posUnit ? layers[layer].posUnit : ""
        }`
      )
      const backgroundHeight = layers[layer].height
        ? `${layers[layer].height}${layers[layer].widthHeightUnit}`
        : "auto"
      const backgroundWidth = layers[layer].width
        ? `${layers[layer].width}${layers[layer].widthHeightUnit}`
        : "auto"
      backgroundSize.push(`${backgroundHeight} ${backgroundWidth}`)
    }
  })
  background = `${background.join(", ")};`
  blendMode = isBlendMode
    ? ` background-blend-mode: ${blendMode.join(", ")}; `
    : ""
  repeat = isRepeat ? ` background-repeat: ${repeat.join(", ")};` : ""
  backgroundPosition =
    posX || posY
      ? ` background-position: ${backgroundPosition.join(", ")};`
      : ""
  backgroundSize =
    height || width ? ` background-size: ${backgroundSize.join(", ")};` : ""
  return `background: ${background}${blendMode}${repeat}${backgroundPosition}${backgroundSize}`
}

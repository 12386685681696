import React, { useState, useEffect } from "react"

import { InputSlider, InputStep, VariablUnit, ToolTip } from "../common"

import { geenrateBackground } from "./utils"

import { backgroundBlendModes } from "./data"

import "./layer.css"

const REPEATS = [
  {
    title: "No Repeat",
    name: "no-repeat",
    icon: "ge-no-repeat",
  },
  {
    title: "Repeat",
    name: "repeat-repeat",
    icon: "ge-repeat-repeat",
  },
  {
    title: "Repeat X",
    name: "repeat-x",
    icon: "ge-repeat-x",
  },
  {
    title: "Repeat Y",
    name: "repeat-y",
    icon: "ge-repeat-y",
  },
  {
    title: "Space",
    name: "space",
    icon: "ge-repeat-repeat",
  },
  {
    title: "Round",
    name: "round",
    icon: "ge-repeat-repeat",
  },
]

const Layer = ({
  state,
  setState,
  layer,
  layers,
  showHide,
  handleBlendMode,
  handleLayerProperties,
  removeLayer,
  duplicateLayer,
  handleUnitChange,
}) => {
  const [visibility, setVisibility] = useState(false)
  useEffect(() => {
    window.addEventListener("keydown", handleKeyEvent)
    return () => {
      window.removeEventListener("keydown", handleKeyEvent)
    }
  })
  const handleKeyEvent = e => {
    if (Object.keys(layers).length > 1) {
      if (e.key === "Delete") {
        removeLayer(e, layer)
      }
    }
  }
  const handleDrag = e => {
    if (e.altKey) {
      duplicateLayer(layer)
    }
  }
  return (
    <li
      className={`layer-list ${layers[layer].show ? "shown" : "hidden"} ${
        layer === state.activeLayer ? "active" : "not-active"
      }`}
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.stopPropagation()
        setState({ ...state, activeLayer: layer })
      }}
    >
      <div
        draggable={true}
        role="button"
        tabIndex={0}
        onKeyPress={() => false}
        onDragStart={handleDrag}
        className="layer"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => false}
            onClick={e => showHide(e, layer)}
            style={{
              width: 48,
              height: 48,
              background: geenrateBackground(layers[layer]),
              borderRadius: 3,
            }}
          />
          <p className="layer-name">{layers[layer].name}</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ul className="layer-properties-lists">
            <li
              role="button"
              tabIndex={1}
              onClick={e => e.stopPropagation()}
              onKeyPress={() => false}
              className="layer-properties"
            >
              <span className="icon hover-contrast ge-blend" />
              <ul className="blend-modes">
                {backgroundBlendModes.map(mode => (
                  <li
                    style={{ fontSize: 14, lineHeight: "20px" }}
                    className={
                      mode.property === layers[layer].blendMode
                        ? "active"
                        : "not-active"
                    }
                    onClick={e => handleBlendMode(e, layer, mode.property)}
                    key={mode.property}
                  >
                    {mode.title}
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => false}
                onClick={() => setVisibility(!visibility)}
              >
                <ToolTip title="Layer Configuration">
                  <span
                    className={`icon ${
                      visibility ? "contrast" : ""
                    } hover-contrast ge-repeat`}
                  />
                </ToolTip>
              </div>
            </li>
            <li
              style={{
                pointerEvents: Object.keys(layers).length > 1 ? "all" : "none",
              }}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={e => removeLayer(e, layer)}
              >
                <ToolTip
                  title={
                    layer === state.activeLayer
                      ? "Remove this Layer[del]"
                      : "Remove this Layer"
                  }
                >
                  <span className="icon hover-contrast ge-cross" />
                </ToolTip>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {visibility && (
        <div
          draggable={true}
          onDragStart={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className="layer-extras"
        >
          <ul>
            <li className="layer-repeat">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Repeat</p>
                <ul
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {REPEATS.map((repeat, index) => (
                    <li
                      className={`${
                        repeat.name === layers[layer].repeat
                          ? "active"
                          : "not-active"
                      }`}
                      key={index}
                      onClick={e =>
                        handleLayerProperties(e, layer, "repeat", repeat.name)
                      }
                    >
                      <ToolTip title={`  ${repeat.title}  `}>
                        <span className={`icon  ${repeat.icon}`} />
                      </ToolTip>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Width</p>
                <div>
                  <InputSlider
                    style={{
                      position: "relative",
                    }}
                    onChange={e =>
                      handleLayerProperties(e, layer, "width", e.target.value)
                    }
                    value={layers[layer].width || 0}
                  />
                </div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: -25,
                  }}
                >
                  <InputStep
                    value={layers[layer].width || 0}
                    onChange={value =>
                      handleLayerProperties(undefined, layer, "width", value)
                    }
                  />
                  <VariablUnit
                    handleUnitChange={handleUnitChange}
                    type={layers[layer].widthHeightUnit}
                    name="widthHeightUnit"
                  />
                </p>
              </div>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Height</p>
                <div>
                  <InputSlider
                    style={{
                      position: "relative",
                    }}
                    onChange={e =>
                      handleLayerProperties(e, layer, "height", e.target.value)
                    }
                    value={layers[layer].height || 0}
                  />
                </div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: -25,
                  }}
                >
                  <InputStep
                    value={layers[layer].height || 0}
                    onChange={value =>
                      handleLayerProperties(undefined, layer, "height", value)
                    }
                  />
                  <VariablUnit
                    handleUnitChange={handleUnitChange}
                    type={layers[layer].widthHeightUnit}
                    name="widthHeightUnit"
                  />
                </p>
              </div>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Pos. X</p>
                <div>
                  <InputSlider
                    style={{
                      position: "relative",
                    }}
                    onChange={e =>
                      handleLayerProperties(e, layer, "posX", e.target.value)
                    }
                    value={layers[layer].posX || 0}
                  />
                </div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: -25,
                  }}
                >
                  <InputStep
                    value={layers[layer].posX || 0}
                    onChange={value =>
                      handleLayerProperties(undefined, layer, "posX", value)
                    }
                  />
                  <VariablUnit
                    handleUnitChange={handleUnitChange}
                    type={layers[layer].posUnit}
                    name="posUnit"
                  />
                </p>
              </div>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Pos. Y</p>
                <div>
                  <InputSlider
                    style={{
                      position: "relative",
                    }}
                    onChange={e =>
                      handleLayerProperties(e, layer, "posY", e.target.value)
                    }
                    value={layers[layer].posY || 0}
                  />
                </div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: -25,
                  }}
                >
                  <InputStep
                    value={layers[layer].posY || 0}
                    onChange={value =>
                      handleLayerProperties(undefined, layer, "posY", value)
                    }
                  />
                  <VariablUnit
                    handleUnitChange={handleUnitChange}
                    type={layers[layer].posUnit}
                    name="posUnit"
                  />
                </p>
              </div>
            </li>
          </ul>
        </div>
      )}
    </li>
  )
}

export default Layer

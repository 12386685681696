import React from "react"

import { InputSlider, InputStep } from "../common"

import { radialShapes } from "./data"

const GradientConfiguration = ({
  layers,
  activeLayer,
  handleAngle,
  handlePosition,
  handleShape,
}) => {
  return (
    <ul className="angle-center-xy">
      {typeof layers[activeLayer].angle !== "undefined" && (
        <li
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ marginBottom: 0 }}>
            <span className="icon ge-angle" /> Angle
          </p>
          <div>
            <InputSlider
              max={360}
              value={layers[activeLayer].angle}
              onChange={e => handleAngle(e.target.value)}
            />
          </div>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 90,
              marginLeft: -57,
            }}
          >
            <InputStep
              max={360}
              value={layers[activeLayer].angle}
              onChange={value => handleAngle(value)}
            />
            <span>°</span>
          </p>
        </li>
      )}
      {typeof layers[activeLayer].position !== "undefined" && (
        <>
          {(layers[activeLayer].name === "Radial" ||
            layers[activeLayer].name === "R.Radial") && (
            <li
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: 0 }}>
                <span className="icon ge-exchange" /> Type
              </p>
              <div
                mode="button"
                tabIndex={1}
                className="gradient-shape"
                style={{ position: "relative" }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p>{layers[activeLayer].shape.name}</p>
                    <span className="icon ge-chevron-down" />
                  </div>
                  <ul
                    style={{
                      position: "absolute",
                      background: "#fff",
                      borderRadius: 3,
                      right: 0,
                      width: 203,
                      padding: "20px 24px",
                      fontSize: 14,
                      lineHeight: "20px",
                      boxShadow: "0px 16px 64px rgba(13, 0, 77, 0.16)",
                      zIndex: 10,
                    }}
                  >
                    {radialShapes.map(shape => (
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={e => handleShape(e, shape)}
                        key={shape.name}
                        className={
                          layers[activeLayer].shape.name === shape.name
                            ? "active"
                            : "not-active"
                        }
                      >
                        {shape.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          )}
          <li
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0 }}>
              <span className="icon ge-x-axis" /> Center X
            </p>
            <div>
              <InputSlider
                onChange={e =>
                  handlePosition([
                    e.target.value,
                    layers[activeLayer].position.y,
                  ])
                }
                value={layers[activeLayer].position.x}
              />
            </div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputStep
                style={{ width: 28 }}
                value={layers[activeLayer].position.x}
                onChange={value =>
                  handlePosition([value, layers[activeLayer].position.y])
                }
              />
              <span>em</span>
            </p>
          </li>
          <li
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0 }}>
              <span className="icon ge-x-axis" /> Center Y
            </p>
            <div>
              <InputSlider
                onChange={e =>
                  handlePosition([
                    layers[activeLayer].position.x,
                    e.target.value,
                  ])
                }
                value={layers[activeLayer].position.y}
              />
            </div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputStep
                style={{ width: 28 }}
                value={layers[activeLayer].position.y}
                onChange={value =>
                  handlePosition([layers[activeLayer].position.x, value])
                }
              />
              em
            </p>
          </li>
        </>
      )}
    </ul>
  )
}

export default GradientConfiguration

import React from "react"

const About = () => {
  return (
    <div style={{ display: "flex", marginTop: 45 }}>
      <div style={{ flex: 0.5 }}>
        <p style={{ marginTop: 15 }}>
          Gradient Editor is the most advanced CSS gradient editor yet. It will
          allow you to create gradient patterns, fully responsible backgrounds
          along with blend modes and multiple layers.
        </p>
        <p style={{ marginTop: 15 }}>
          One of the main benefit you can get is CSS code. Does not matter how
          complex the gradient or background is, you will always get the CSS
          code to use in your website or app and load those pages faster than
          image. Beside CSS code, you can download JPG or PNG image at ultra
          high resolution to use in any kind of graphic application for screen
          or print.
        </p>
        <p style={{ marginTop: 15 }}>
          Found a bug? Have any features in mind? Or do not understand how to
          use? Let use know, we are always here to help and collaborate.
        </p>
      </div>
    </div>
  )
}

export default About

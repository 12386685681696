import React, { useState, useRef, useEffect } from "react"

import { generateCode } from "./utils"

import { PreviewGradient } from "./_styles"
import { ToolTip } from "../common"
import DownloadBox from "../item/downloadbox"

import "./preview.css"

const Preview = ({ state }) => {
  const [preViewRatio, setPreViewRatio] = useState({
    activeRatio: {
      width: 3,
      height: 2,
    },
    height: null,
    width: null,
  })
  const [show, setShow] = useState(false)
  const handleShow = () => {
    setShow(!show)
  }
  const preViewArea = useRef(null)
  const preViewElement = useRef(null)
  useEffect(() => {
    handlePrevioRatio()
    window.addEventListener("keypress", handleKeyBoardShortcut)
    return () => {
      window.removeEventListener("keypress", handleKeyBoardShortcut)
    }
  }, [preViewRatio.activeRatio.width, preViewRatio.activeRatio.height])
  const handlePrevioRatio = () => {
    const currentPreViewArea = preViewArea.current
    if (currentPreViewArea) {
      const { offsetWidth, offsetHeight } = currentPreViewArea
      const height =
        (offsetWidth / preViewRatio.activeRatio.width) *
        preViewRatio.activeRatio.height
      const width = offsetWidth
      setPreViewRatio({ ...preViewRatio, height, width })
    }
  }
  const requestFullScreen = el => {
    if (el.requestFullscreen) {
      el.requestFullscreen()
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen()
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen()
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen()
    }
  }
  const handleKeyBoardShortcut = e => {
    const { code } = e
    // if (code === "KeyF") {
    //   requestFullScreen(preViewElement.current)
    // }
  }
  const generatedCode = generateCode(state)
  return (
    <div ref={preViewArea} style={{ flex: 1, marginRight: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <h4 style={{ fontSize: 14, fontWeight: 300, color: "#716CA6" }}>
          Final Preview
        </h4>
        <ul
          className="live-preview-mode"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            lineHeight: 1,
          }}
        >
          <li>
            <p>
              {preViewRatio.activeRatio.width}:{preViewRatio.activeRatio.height}
            </p>
          </li>
          <li>
            <div
              role="button"
              tabIndex="0"
              style={{ cursor: "pointer" }}
              onKeyPress={() => false}
              onClick={() => setShow(true)}
            >
              <ToolTip title="Download Your Codioful">
                <span className="icon hover-contrast icon-download" />
              </ToolTip>
            </div>
          </li>
          <li
            className="preview-button"
            onClick={() => requestFullScreen(preViewElement.current)}
            style={{ marginLeft: 18 }}
          >
            <ToolTip title="Fullscreen [F]">
              <span className="icon hover-contrast ge-maximize" />
            </ToolTip>
          </li>
        </ul>
      </div>
      <PreviewGradient
        ref={preViewElement}
        onClick={e => requestFullScreen(e.target)}
        gradientCode={generatedCode}
        style={{
          width: preViewRatio.width,
          height: preViewRatio.height,
          borderRadius: 3,
        }}
      />
      {show && (
        <DownloadBox
          id={`your-codioful-${state.activeLayer}`}
          CSS={generateCode(state)}
          handleShow={handleShow}
        />
      )}
    </div>
  )
}

export default Preview

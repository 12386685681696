import React from "react"

import { RotatingBar, EditorPickerGround, ToolTip } from "../common"

import { geenrateBackground } from "./utils"

import "./gradientpicker.css"

const POSITIONS = [
  {
    position: [0, 0],
    top: 25,
    left: 25,
  },
  {
    position: [0, 50],
    top: 50,
    left: 25,
  },
  {
    position: [0, 100],
    top: 75,
    left: 25,
  },
  {
    position: [50, 0],
    top: 25,
    left: 50,
  },
  {
    position: [50, 50],
    top: 50,
    left: 50,
  },
  {
    position: [50, 100],
    top: 75,
    left: 50,
  },
  {
    position: [100, 0],
    top: 25,
    left: 75,
  },
  {
    position: [100, 50],
    top: 50,
    left: 75,
  },
  {
    position: [100, 100],
    top: 75,
    left: 75,
  },
]

const GradientPicker = ({
  layers,
  activeLayer,
  handlePosition,
  handleAngle,
  reverseThePoints,
  transformLayer,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 15,
          alignItems: "center",
        }}
      >
        <h4
          style={{
            color: "#716CA6",
            fontSize: 14,
            fontWeight: "400",
          }}
        >
          Gradient Picker
        </h4>
        <ul style={{ display: "flex", alignItems: "center", lineHeight: 0 }}>
          <li>
            <div
              role="button"
              tabIndex={0}
              style={{ cursor: "pointer" }}
              onKeyPress={() => false}
              onClick={reverseThePoints}
            >
              <ToolTip title=" Reverse ">
                <span className="icon hover-contrast ge-flip" />
              </ToolTip>
            </div>
          </li>
        </ul>
      </div>
      <EditorPickerGround
        type={layers[activeLayer].type}
        handlePosition={handlePosition}
        background={geenrateBackground(layers[activeLayer])}
      >
        {typeof layers[activeLayer].position !== "undefined" && (
          <ul>
            {POSITIONS.map((position, index) => (
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => false}
                className="position-dot"
                key={index}
                onClick={e => handlePosition(e, position.position)}
                style={{
                  position: "absolute",
                  top: `calc(${position.top}% - 5px)`,
                  left: `calc(${position.left}% - 5px)`,
                  width: 6,
                  height: 6,
                  background: "#fff",
                  border: "2px solid #0D004D",
                  cursor: "pointer",
                  borderRadius: 100,
                  boxSizing: "content-box",
                }}
              />
            ))}
          </ul>
        )}
        {typeof layers[activeLayer].angle !== "undefined" && (
          <RotatingBar
            angle={layers[activeLayer].angle}
            setAngle={handleAngle}
          />
        )}
      </EditorPickerGround>
      <ul
        className="gradients-type"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          padding: "16px 5px",
          background: "#F6F5FF",
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          marginBottom: 16,
        }}
      >
        <li
          className={
            layers[activeLayer].name === "Linear" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "linear")}
        >
          <span className="icon gi-linear-gradient" />
        </li>
        <li
          className={
            layers[activeLayer].name === "R.Linear" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "rlinear")}
        >
          <span className="icon gi-repeating-linear-gradient" />
        </li>
        <li
          className={
            layers[activeLayer].name === "Radial" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "radial")}
        >
          <span className="icon gi-radial-gradient" />
        </li>
        <li
          className={
            layers[activeLayer].name === "R.Radial" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "rradial")}
        >
          <span className="icon gi-repeating-radial-gradient" />
        </li>
        <li
          className={
            layers[activeLayer].name === "Conic" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "conic")}
        >
          <span className="icon gi-conic-gradient" />
        </li>
        <li
          className={
            layers[activeLayer].name === "R.Conic" ? "active" : "not-active"
          }
          style={{ cursor: "pointer" }}
          onClick={e => transformLayer(e, "rconic")}
        >
          <span className="icon gi-repeating-conic-gradient" />
        </li>
        {/* <li style={{ cursor: "pointer" }}>
          <span className="icon gi-image-gradient" />
        </li> */}
      </ul>
    </>
  )
}

export default GradientPicker

import React, { useState, useEffect, useRef } from "react"
import { v4 as uuid } from "uuid"

import About from "./About"
import CodeViewer from "./CodeViewer"
import GradientConfiguration from "./GradientConfiguration"
import GradientPicker from "./GradientPicker"
import GradientSlider from "./GradientSlider"
import Layers from "./Layers"
import Preview from "./Preview"

import { ColorPicker, MinButton, QuickButton } from "../common"

import { generateCode } from "./utils"

import { gradientDefaults, defaultState } from "./data"

import "./editor.css"

const Editor = () => {
  const [state, setState] = useState(defaultState)

  const copyCodeButton = useRef(null)
  useEffect(() => {
    // const resetOnClickOpenFunction = resetOnClickOpen(state, setState)
    // window.addEventListener("click", resetOnClickOpenFunction)
    window.addEventListener("keydown", handleKeyBoardShortcut)
    return () => {
      // window.removeEventListener("click", resetOnClickOpenFunction)
      window.removeEventListener("keydown", handleKeyBoardShortcut)
    }
  }, [state])

  const handleKeyBoardShortcut = e => {
    const { code } = e
    if (code === "KeyN") {
      addLayer(e, "linear")
    } else if (code === "KeyC") {
      if (copyCodeButton.current) {
        copyCodeButton.current.children[0].click()
      }
    }
  }
  const { layers, activeLayer, colorPicker, activePoint } = state
  const transformLayer = (e, type) => {
    e.stopPropagation()
    const exactType = gradientDefaults[type].type
    if (e.ctrlKey) {
      addLayer(e, type)
    } else {
      if (exactType === layers[activeLayer].type) {
        // Do nothing...
      } else if (
        exactType.includes(layers[activeLayer].type) ||
        layers[activeLayer].type.includes(exactType)
      ) {
        setState({
          ...state,
          layers: {
            ...layers,
            [activeLayer]: {
              ...layers[activeLayer],
              name: gradientDefaults[type].name,
              type: gradientDefaults[type].type,
            },
          },
        })
      } else {
        if (
          (exactType.includes("linear") &&
            layers[activeLayer].type.includes("conic")) ||
          (exactType.includes("conic") &&
            layers[activeLayer].type.includes("linear"))
        ) {
          setState({
            ...state,
            layers: {
              ...layers,
              [activeLayer]: {
                ...layers[activeLayer],
                name: gradientDefaults[type].name,
                type: gradientDefaults[type].type,
                position: gradientDefaults[type].position,
              },
            },
          })
        } else if (
          (exactType.includes("radial") &&
            layers[activeLayer].type.includes("conic")) ||
          (exactType.includes("conic") &&
            layers[activeLayer].type.includes("radial"))
        ) {
          setState({
            ...state,
            layers: {
              ...layers,
              [activeLayer]: {
                ...layers[activeLayer],
                name: gradientDefaults[type].name,
                type: gradientDefaults[type].type,
                angle: gradientDefaults[type].angle,
                shape: gradientDefaults[type].shape,
              },
            },
          })
        } else if (
          (exactType.includes("linear") &&
            layers[activeLayer].type.includes("radial")) ||
          (exactType.includes("radial") &&
            layers[activeLayer].type.includes("linear"))
        ) {
          setState({
            ...state,
            layers: {
              ...layers,
              [activeLayer]: {
                ...layers[activeLayer],
                name: gradientDefaults[type].name,
                type: gradientDefaults[type].type,
                angle: gradientDefaults[type].angle,
                shape: gradientDefaults[type].shape,
                position: gradientDefaults[type].position,
              },
            },
          })
        }
      }
    }
  }
  const addLayer = (e, type) => {
    e.stopPropagation()
    const totalLayers = Object.keys(layers).length
    const activeLayer = uuid()
    setState({
      ...state,
      layers: {
        [activeLayer]: gradientDefaults[type],
        ...layers,
      },
      activeLayer,
    })
  }
  const duplicateLayer = layer => {
    const newUniqueId = uuid()
    const newLayer = layers[layer]
    setState({
      ...state,
      layers: {
        [newUniqueId]: newLayer,
        ...layers,
      },
    })
  }
  const addPoint = e => {
    e.stopPropagation()
    const width = e.target.offsetWidth
    const distanceFromLeft = e.target.offsetLeft
    const x = e.clientX
    const actualX = x - distanceFromLeft
    const point = (actualX / width) * 100
    let points = [...layers[activeLayer].points, { point, color: "#333" }]
    points.sort((a, b) => (a.point < b.point ? -1 : 1))
    const activePoint = points.findIndex(onePoint => onePoint.point === point)
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
          activePoint,
        },
      },
      activePoint: true,
    })
  }
  const addConnectedPoint = e => {
    e.stopPropagation()
    const activePoint = layers[activeLayer].activePoint
    const point = layers[activeLayer].points[activePoint - 1].point
    const color = layers[activeLayer].points[activePoint].color
    let currentActivePoints = layers[activeLayer].points[activePoint]
    layers[activeLayer].points[activePoint] = {
      ...currentActivePoints,
      connectedPrev: true,
    }
    let points = [...layers[activeLayer].points, { point, color }]
    points.sort((a, b) => (a.point < b.point ? -1 : 1))
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const removePoint = e => {
    e.stopPropagation()
    const points = layers[activeLayer].points.filter(
      (point, index) => index !== layers[activeLayer].activePoint
    )
    const activePoint =
      layers[activeLayer].activePoint === layers[activeLayer].points.length - 1
        ? layers[activeLayer].activePoint - 1
        : layers[activeLayer].activePoint
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
          activePoint,
        },
      },
    })
  }
  const onPointBlur = e => {
    setState({
      ...state,
      activePoint: false,
    })
  }
  const handleClickOnPoint = (e, activePoint) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          activePoint,
        },
      },
      colorPicker: { show: false, x: e.clientX, y: e.clientY },
      activePoint: true,
    })
  }
  const handleDragPoint = (value, activePoint) => {
    const points = [...layers[activeLayer].points]
    points[activePoint] = { ...points[activePoint], point: value }
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const onColorChange = (props, event) => {
    event.stopPropagation()
    event.preventDefault()

    const {
      hex,
      rgb: { r, g, b, a },
    } = props
    const isAlpha = a < 1
    const activePoint = layers[activeLayer].activePoint
    const points = [...layers[activeLayer].points]
    points[activePoint] = {
      ...points[activePoint],
      color: isAlpha ? `rgba(${r}, ${g}, ${b}, ${a})` : hex,
    }
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const handlePosition = (e, [x, y]) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          position: {
            x,
            y,
          },
        },
      },
    })
  }
  const handleAngle = angle => {
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          angle,
        },
      },
    })
  }
  const handleShape = (e, shape) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          shape,
        },
      },
    })
  }
  const handlePointPosition = value => {
    const activePoint = layers[activeLayer].activePoint
    const points = layers[activeLayer].points
    points[activePoint] = { ...points[activePoint], point: value }
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const handlePointPositionSorted = e => {
    const activePoint = layers[activeLayer].activePoint
    const points = layers[activeLayer].points
    points[activePoint] = { ...points[activePoint], point: e.target.value }
    points.sort((a, b) => (a.point < b.point ? -1 : 1))
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const handlePrevNextPoint = (e, mode) => {
    e.stopPropagation()
    const activePoint = layers[activeLayer].activePoint
    const totalPoints = layers[activeLayer].points.length
    let newPoint = activePoint
    let shouldChange = false
    if (mode === "prev") {
      if (activePoint > 0) {
        newPoint--
        shouldChange = true
      }
    } else {
      if (activePoint < totalPoints - 1) {
        newPoint++
        shouldChange = true
      }
    }
    if (shouldChange) {
      setState({
        ...state,
        layers: {
          ...layers,
          [activeLayer]: {
            ...layers[activeLayer],
            activePoint: newPoint,
          },
        },
      })
    }
  }
  const handleBlendMode = (e, currentLayer, blendMode) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [currentLayer]: {
          ...layers[currentLayer],
          blendMode,
        },
      },
      isBlendMode: true,
    })
  }
  const removeLayer = (e, layer) => {
    //remove doesn;t work properly
    e.stopPropagation()
    let activeLayer = state.activeLayer
    const layerKeys = Object.keys(layers)
    const totalLayers = layerKeys.length
    const layerIndex = layerKeys.findIndex(key => key === layer)
    if (layer === activeLayer) {
      if (layerIndex + 1 === totalLayers) {
        activeLayer = layerKeys[layerIndex - 1]
      } else {
        activeLayer = layerKeys[layerIndex + 1]
      }
    }
    const newLayers = { ...layers }
    delete newLayers[layer]
    setState({
      ...state,
      layers: { ...newLayers },
      activeLayer,
    })
  }
  const showHide = (e, layer) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [layer]: {
          ...layers[layer],
          show: !layers[layer].show,
        },
      },
    })
  }
  const handleUnitChange = (e, name, type) => {
    e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          [name]: type,
        },
      },
    })
  }
  const reverseThePoints = e => {
    e.stopPropagation()
    let points = [...layers[activeLayer].points]
    let reveresedPoint = [...points].reverse()
    points = points.map((point, index) => ({
      point: point.point,
      color: reveresedPoint[index].color,
    }))
    setState({
      ...state,
      layers: {
        ...layers,
        [activeLayer]: {
          ...layers[activeLayer],
          points,
        },
      },
    })
  }
  const handleLayerProperties = (e, currentLayer, type, value) => {
    typeof e !== "undefined" && e.stopPropagation()
    setState({
      ...state,
      layers: {
        ...layers,
        [currentLayer]: {
          ...layers[currentLayer],
          [type]: value,
        },
      },
      [type]: true,
    })
  }
  const handleColorPickerBlur = () => {
    setState({
      ...state,
      colorPicker: { ...state.colorPicker, show: false },
    })
  }
  const handleColorPicker = e => {
    e.stopPropagation()
    setState({
      ...state,
      colorPicker: { ...state.colorPicker, show: true },
    })
  }
  const generatedCode = generateCode(state)
  const handleCopy = () => {
    if (typeof navigator !== "undefined") {
      navigator.clipboard.writeText(generatedCode)
    }
  }

  return (
    <>
      <div className="editor">
        <div style={{ display: "flex", marginBottom: 50 }}>
          <Preview state={state} />
          <div style={{ flex: 1, marginLeft: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginRight: 20 }}>
                <GradientPicker
                  layers={layers}
                  activeLayer={activeLayer}
                  handlePosition={handlePosition}
                  handleAngle={handleAngle}
                  reverseThePoints={reverseThePoints}
                  transformLayer={transformLayer}
                />
                <GradientSlider
                  activeLayer={activeLayer}
                  addPoint={addPoint}
                  layers={layers}
                  handleClickOnPoint={handleClickOnPoint}
                  onPointBlur={onPointBlur}
                  activePoint={activePoint}
                  addConnectedPoint={addConnectedPoint}
                  handleDragPoint={handleDragPoint}
                  removePoint={removePoint}
                  handleColorPickerBlur={handleColorPickerBlur}
                  handleColorPicker={handleColorPicker}
                  onColorChange={onColorChange}
                  handlePointPosition={handlePointPosition}
                  handlePointPositionSorted={handlePointPositionSorted}
                  handlePrevNextPoint={handlePrevNextPoint}
                />
                <GradientConfiguration
                  layers={layers}
                  activeLayer={activeLayer}
                  handleAngle={handleAngle}
                  handlePosition={handlePosition}
                  handleShape={handleShape}
                />
              </div>
              <Layers
                state={state}
                addLayer={addLayer}
                layers={layers}
                setState={setState}
                showHide={showHide}
                handleLayerProperties={handleLayerProperties}
                handleBlendMode={handleBlendMode}
                removeLayer={removeLayer}
                duplicateLayer={duplicateLayer}
                handleUnitChange={handleUnitChange}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 3, marginRight: 25 }}>
            <div className="layer-head">
              <h4>CSS Code</h4>
              <ul style={{ display: "flex" }}>
                {/* <li style={{ marginLeft: 15 }}>
                  <p ref={copyCodeButton}>
                    <MinButton
                      title="Submit"
                      onClick={handleCopy}
                      afterClickTitle="Submitted!"
                    />
                  </p>
                </li> */}
                <li style={{ marginLeft: 15 }}>
                  <p ref={copyCodeButton}>
                    <QuickButton
                      CSS={generatedCode}
                      title="Code"
                      onHoverText="Copy Code"
                      leftIcon="icon-copy larger-icon"
                      leftIconSuccess="icon-check larger-icon"
                    />
                  </p>
                </li>
              </ul>
            </div>
            <CodeViewer code={generatedCode} />
          </div>
        </div>
        <About />
      </div>
      {colorPicker.show && (
        <div
          onClick={e => e.stopPropagation()}
          style={{
            position: "fixed",
            left: colorPicker.x + 30,
            top: colorPicker.y - 25,
            zIndex: 2,
          }}
        >
          <ColorPicker
            onChange={onColorChange}
            color={
              layers[activeLayer].points[layers[activeLayer].activePoint].color
            }
          />
        </div>
      )}
    </>
  )
}

export default Editor

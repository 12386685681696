export const radialShapes = [
  {
    name: "Ellipse",
    type: "ellipse",
    extends: "",
  },
  {
    name: "Ellipse Farthest Corner",
    type: "ellipse",
    extends: "farthest-corner",
  },
  {
    name: "Ellipse Closest Side",
    type: "ellipse",
    extends: "closest-side",
  },
  {
    name: "Ellipse Closest Corner",
    type: "ellipse",
    extends: "closest-corner",
  },
  {
    name: "Ellipse Farthest Side",
    type: "ellipse",
    extends: "farthest-side",
  },
  {
    name: "Circle",
    type: "circle",
    extends: "",
  },
  {
    name: "Circle Farthest Corner",
    type: "circle",
    extends: "farthest-corner",
  },
  {
    name: "Circle Closest Side",
    type: "circle ",
    extends: "closest-side",
  },
  {
    name: "Circle Closest Corner",
    type: "circle",
    extends: "closest-corner",
  },
  {
    name: "Circle Farthest Side",
    type: "circle",
    extends: "farthest-side",
  },
]

import { v4 as uuid } from "uuid"

const uniqueId = uuid()

export const defaultState = {
  layers: {
    [uniqueId]: {
      name: "Linear",
      type: "linear-gradient",
      angle: 180,
      points: [
        { point: 0, color: "#FF0055", connectedPrev: false },
        { point: 100, color: "#000066", connectedPrev: false },
      ],
      activePoint: 0,
      blendMode: "",
      repeat: "",
      posX: 0,
      posY: 0,
      posUnit: "%",
      width: 0,
      height: 0,
      widthHeightUnit: "%",
      show: true,
    },
  },
  colorPicker: {
    show: false,
    x: 0,
    y: 0,
  },
  activeLayer: uniqueId,
  isBlendMode: false,
  repeat: false,
  posX: false,
  posY: false,
  width: false,
  height: false,
  activePoint: false,
}

export const gradientDefaults = {
  linear: {
    name: "Linear",
    type: "linear-gradient",
    angle: 180,
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 100, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
  rlinear: {
    name: "R.Linear",
    type: "repeating-linear-gradient",
    angle: 180,
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 33.33, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
  radial: {
    name: "Radial",
    type: "radial-gradient",
    shape: {
      name: "Ellipse",
      type: "ellipse",
      extends: "",
    },
    position: {
      x: 50,
      y: 50,
    },
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 100, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
  rradial: {
    name: "R.Radial",
    type: "repeating-radial-gradient",
    shape: {
      name: "Ellipse",
      type: "ellipse",
      extends: "",
    },
    position: {
      x: 50,
      y: 50,
    },
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 33.33, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
  conic: {
    name: "Conic",
    type: "conic-gradient",
    angle: 0,
    position: {
      x: 50,
      y: 50,
    },
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 100, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
  rconic: {
    name: "R.Conic",
    type: "repeating-conic-gradient",
    angle: 0,
    position: {
      x: 50,
      y: 50,
    },
    points: [
      { point: 0, color: "#FF0055", connectedPrev: false },
      { point: 33.33, color: "#000066", connectedPrev: false },
    ],
    activePoint: 0,
    blendMode: "",
    repeat: "",
    posX: 0,
    posY: 0,
    width: 0,
    height: 0,
    show: true,
  },
}

import React from "react"

import Editor from "../components/editor/Editor"

import { Layout, Container, SEO, Sheet } from "../components/common"

export default () => {
  return (
    <Layout noIntro title="Codioful Editor">
      <Container>
        <SEO title="Codioful Editor" />
        <Sheet fullWidth mt={40}>
          <Editor />
        </Sheet>
      </Container>
    </Layout>
  )
}

import React from "react"
import { ReactSortable } from "react-sortablejs"

import { ToolTip } from "../common"

import Layer from "./Layer"

const Layers = ({
  state,
  addLayer,
  layers,
  setState,
  showHide,
  handleLayerProperties,
  handleBlendMode,
  removeLayer,
  duplicateLayer,
  handleUnitChange,
}) => {
  let layerKeys = Object.keys(layers)
  layerKeys = layerKeys.map(key => ({ id: key }))
  const handleSort = sortedLayerKeys => {
    let newLayers = {}
    sortedLayerKeys.forEach(key => {
      newLayers = {
        ...newLayers,
        [key.id]: layers[key.id],
      }
    })
    setState({ ...state, layers: newLayers })
  }
  return (
    <div className="layer-panel">
      <div className="layer-head">
        <h4>Layer Panel</h4>
        <div style={{ position: "relative" }}>
          <p onClick={e => addLayer(e, "linear")}>
            <ToolTip title="Add New Layer[N]">
              <span className="icon hover-contrast ge-plus" />
            </ToolTip>
          </p>
        </div>
      </div>
      <div>
        <ul>
          <ReactSortable list={layerKeys} setList={handleSort}>
            {Object.keys(layers).map(layer => (
              <Layer
                key={layer}
                state={state}
                setState={setState}
                layers={layers}
                layer={layer}
                showHide={showHide}
                handleBlendMode={handleBlendMode}
                handleLayerProperties={handleLayerProperties}
                removeLayer={removeLayer}
                duplicateLayer={duplicateLayer}
                handleUnitChange={handleUnitChange}
              />
            ))}
          </ReactSortable>
        </ul>
      </div>
    </div>
  )
}

export default Layers

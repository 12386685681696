import { makeColorStrip } from "./makeColorStrip"

export const geenrateBackground = ({
  type,
  shape,
  position,
  angle,
  points,
}) => {
  const colorstrip = makeColorStrip(points)
  if (type === "linear-gradient" || type === "repeating-linear-gradient") {
    return `${type}(${angle}deg, ${colorstrip})`
  }
  if (type === "radial-gradient" || type === "repeating-radial-gradient") {
    return `${type}(${shape.type}${
      shape.extends ? ` ${shape.extends}` : ""
    } at ${position.x}% ${position.y}% , ${colorstrip})`
  }
  if (type === "conic-gradient" || type === "repeating-conic-gradient") {
    return `${type}(from ${angle}deg at ${position.x}% ${position.y}%, ${colorstrip})`
  }
}

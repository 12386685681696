import React, { useEffect } from "react"

import { InputSlider, InputStep } from "../common"

import { makeStripe } from "./utils"

import { ColorSlider } from "./_styles"

const GradientSlider = ({
  activeLayer,
  addPoint,
  layers,
  onPointBlur,
  handleClickOnPoint,
  activePoint,
  addConnectedPoint,
  handleDragPoint,
  removePoint,
  handleColorPicker,
  handleColorPickerBlur,
  onColorChange,
  handlePointPosition,
  handlePointPositionSorted,
  handlePrevNextPoint,
}) => {
  useEffect(() => {
    window.addEventListener("click", onPointBlur)
    window.addEventListener("click", handleColorPickerBlur)
    return () => {
      window.removeEventListener("click", onPointBlur)
      window.removeEventListener("click", handleColorPickerBlur)
    }
  })
  return (
    <>
      <div style={{ paddingBottom: 40 }}>
        <div
          className="color-stripe"
          style={{
            width: "100%",
            height: 16,
            borderRadius: 8,
            background: makeStripe(layers[activeLayer].points),
          }}
        >
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => false}
            onClick={addPoint}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            {layers[activeLayer].points.map((point, index) => (
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => false}
                key={index}
                onDoubleClick={handleColorPicker}
                onClick={e => handleClickOnPoint(e, index)}
                className={`color-slider ${
                  activePoint && index === layers[activeLayer].activePoint
                    ? "active"
                    : "not-active"
                }`}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  top: 15,
                }}
              >
                {activePoint && index === layers[activeLayer].activePoint && (
                  <div
                    style={{
                      position: "absolute",
                      width: "calc(100% - 16px)",
                      margin: "0 8px",
                      top: -25,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 1,
                        height: 15,
                        width: 2,
                        backgroundColor: "#0d004d",
                        left: `calc(${point.point}% - 1px)`,
                      }}
                    />
                  </div>
                )}
                <ColorSlider color={point.color} point={point.point}>
                  <input
                    className={`${point.connectedPrev ? "connected" : ""}`}
                    onChange={e => handleDragPoint(e.target.value, index)}
                    value={point.point}
                    min={0}
                    max={100}
                    style={{
                      position: "relative",
                    }}
                    type="range"
                  />
                </ColorSlider>
                {activePoint &&
                  index === layers[activeLayer].activePoint &&
                  layers[activeLayer].points.length >= 2 && (
                    <div
                      style={{
                        position: "absolute",
                        width: "calc(100% - 16px)",
                        margin: "0 8px",
                        top: -20,
                      }}
                    >
                      {index !== 0 && (
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => false}
                          className={`point ${
                            point.connectedPrev ? "connected" : ""
                          }`}
                          onClick={addConnectedPoint}
                          style={{
                            position: "absolute",
                            left: `calc(${point.point}% - 30px)`,
                          }}
                        >
                          <span className="icon ge-connect" />
                        </div>
                      )}
                      {layers[activeLayer].points.length >= 3 && (
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => false}
                          className="point"
                          onClick={removePoint}
                          style={{
                            position: "absolute",
                            left: `calc(${point.point}% + 10px)`,
                          }}
                        >
                          <span className="icon ge-cross" />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {activePoint && (
        <>
          <ul
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <li
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => false}
                onClick={handleColorPicker}
                style={{
                  backgroundColor:
                    layers[activeLayer].points[layers[activeLayer].activePoint]
                      .color,
                  height: 24,
                  width: 24,
                  cursor: "pointer",
                  borderRadius: 100,
                  boxShadow: "inset 1px 1px 4px rgba(13, 0, 77, 0.16)",
                }}
              />
              <p style={{ marginLeft: 8 }}>
                {
                  layers[activeLayer].points[layers[activeLayer].activePoint]
                    .color
                }
              </p>
            </li>
            <li>
              <ul
                className="color-shortcut"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <li>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => false}
                    onClick={e => {
                      e.stopPropagation()
                      onColorChange({
                        hex: "#fff",
                        rgb: { r: 255, g: 255, b: 255, a: 0 },
                      })
                    }}
                    className="transparent-background"
                    style={{
                      position: "relative",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      zIndex: 1,
                      boxShadow: "inset 1px 1px 4px rgba(13, 0, 77, 0.16)",
                    }}
                  />
                </li>
                <li>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => false}
                    onClick={e => {
                      e.stopPropagation()
                      onColorChange({
                        hex: "#fff",
                        rgb: { r: 255, g: 255, b: 255, a: 1 },
                      })
                    }}
                    style={{
                      position: "relative",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      zIndex: 1,
                      boxShadow: "inset 1px 1px 4px rgba(13, 0, 77, 0.16)",
                    }}
                  />
                </li>
                <li>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => false}
                    onClick={e => {
                      e.stopPropagation()
                      onColorChange({
                        hex: "#000",
                        rgb: { r: 0, g: 0, b: 0, a: 1 },
                      })
                    }}
                    style={{
                      position: "relative",
                      backgroundColor: "#000",
                      cursor: "pointer",
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      zIndex: 1,
                      boxShadow: "inset 1px 1px 4px rgba(13, 0, 77, 0.16)",
                    }}
                  />
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="slidecontainer"
                >
                  <p>
                    <span className="icon ge-position" /> Position
                  </p>
                  <div>
                    <InputSlider
                      value={
                        layers[activeLayer].points[
                          layers[activeLayer].activePoint
                        ].point
                      }
                      onClick={e => e.stopPropagation()}
                      onChange={e => handlePointPosition(e.target.value)}
                      onMouseUp={handlePointPositionSorted}
                    />
                  </div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: 120,
                      marginLeft: -45,
                    }}
                  >
                    <InputStep
                      value={Number(
                        layers[activeLayer].points[
                          layers[activeLayer].activePoint
                        ].point
                      ).toFixed(2)}
                      onChange={handlePointPosition}
                      onMouseUp={handlePointPositionSorted}
                    />
                    <span>%</span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <ul
            className="point-nav"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 30,
            }}
          >
            <li
              className="hover-contrast"
              style={{
                display: "flex",
                alignItems: "center",
                opacity: layers[activeLayer].activePoint ? 1 : 0.25,
              }}
              onClick={e => handlePrevNextPoint(e, "prev")}
            >
              <p className="icon ge-arrow-left" />{" "}
              <p style={{ marginLeft: 15 }}>Prev Stop</p>
            </li>
            <li
              className="hover-contrast"
              style={{
                display: "flex",
                alignItems: "center",
                opacity:
                  layers[activeLayer].activePoint + 1 ===
                  layers[activeLayer].points.length
                    ? 0.25
                    : 1,
              }}
              onClick={e => handlePrevNextPoint(e, "next")}
            >
              <p style={{ marginRight: 15 }}>Next Stop</p>{" "}
              <p className="icon ge-arrow-right" />
            </li>
          </ul>
        </>
      )}
    </>
  )
}

export default GradientSlider

import styled from "styled-components"

export const PreviewGradient = styled.div`
  ${props => props.gradientCode}
`

export const ColorSlider = styled.div`
  input {
    :after {
      left: ${props => props.point}%;
    }
    ::-webkit-slider-thumb {
      background: ${props => props.color};
    }
  }
`
